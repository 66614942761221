html,
body {
  margin: 0;
  height: 100%;
}

.main {
  background-color: #fcf9f1;
}

.wrapper {
  max-width: 1100px;
  min-height: 100vh;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 15px;
  margin-bottom: 0rem;
}
