.nav {
    background-color: #456127;
    border-bottom: #513f33 5px solid !important;
}

.link {
    color: #ffff !important;
}

.hamburgerIcon {
    border-color: white !important;
  }